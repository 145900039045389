import React, { useRef, useEffect } from 'react';

interface ScrollToRightDivProps {
  children: React.ReactNode;
}

function ScrollToRightDiv({ children }: ScrollToRightDivProps) {
  const scrollRef = useRef<HTMLDivElement>(null);

  const isMobile = window.innerWidth <= 768;

  useEffect(() => {
    if (scrollRef.current) {
      (scrollRef.current as HTMLDivElement).scrollLeft = scrollRef.current.scrollWidth;
    }
  }, []);

  return (
    <div
      ref={scrollRef}
      style={{
        overflowY: 'hidden',
        overflowX: 'scroll',
        whiteSpace: 'nowrap',
        scrollbarWidth: 'none', // Hide scrollbar for Firefox
        paddingBottom: isMobile ? '0px' : '0px', // Different padding for mobile and desktop
      }}
    >
      {children} {/* Render children inside this div */}
      {isMobile && <span
      style={{
        color: 'white',
        fontSize: '12px',
        fontWeight: 'bold',
        textAlign: 'center',
        width: '100%',
        display: 'block',
        position: 'sticky',
        bottom: 0, // Stick to the bottom of the parent div
        left: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.5)', // Optional background to ensure readability
        zIndex: 1000, // Ensure it stays above other content
        padding: '5px 0',
        border: '1px solid #252424', // Add border
        borderRadius: '5px', // Add border-radius
      }}><i className="fa-solid fa-arrows-left-right"></i> Scroll here <i className="fa-solid fa-arrows-left-right"></i></span>}
    </div>
  );
}

export default ScrollToRightDiv;
