import React, { useState, useEffect, useRef } from 'react';
import { VictoryChart, VictoryBar, VictoryAxis, VictoryLabel } from 'victory';
import axios from 'axios';
import ScrollToRightDiv from './scrollToRightDiv';

interface TalentPerformanceChartProps {
  talentName: string;
  onFollowersUpdate: (followers: number) => void;
  onBarClick: (barData: { x: string; y: number }) => void;
}

const TalentPerformanceChart: React.FC<TalentPerformanceChartProps> = ({ talentName, onFollowersUpdate, onBarClick }) => {

  const [data, setData] = useState<{ x: string; y: number }[]>([]);
  const [loading, setLoading] = useState(true);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const chartContainerRef = useRef<HTMLDivElement>(null);
  const averageScore = 100000;

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (talentName) {
      fetchInstagramPerformance(talentName);
    }
  }, [talentName]);

  const fetchInstagramPerformance = async (name: string) => {
    const currentHostname = window.location.href;
    const stagingKey = process.env.REACT_APP_KEY_STAGING || 'staging2';
    const linkUrl = currentHostname.includes(`${stagingKey}`)
      ? `https://${stagingKey}.peaxel.me`
      : 'https://peaxel.me';

    const uppername = name.toUpperCase();

    try {
      setLoading(true);
      const response = await axios.get(`${linkUrl}/wp-json/custom/v1/get-instagram-performance/?talent_name=${uppername}`);
      
      const apiData = response.data;
      const sortedWeeks = Object.keys(apiData).sort();
      
      // Map through each week's data and enrich the `validData` array with the new fields
      const validData = sortedWeeks.map(week => ({
        x: week,
        y: parseFloat(apiData[week].score_last_week) || 0,
        date_scrapping: apiData[week].date_scrapping,
        posts_number_total: parseInt(apiData[week].posts_number_total, 10) || 0,
        followers_number_total: parseInt(apiData[week].followers_number_total, 10) || 0,
        likes_number_last_week: parseInt(apiData[week].likes_number_last_week, 10) || 0,
        posts_number_last_week: parseInt(apiData[week].posts_number_last_week, 10) || 0,
        comments_number_last_week: parseInt(apiData[week].comments_number_last_week, 10) || 0,
        engagement_rate_last_week: parseFloat(apiData[week].engagement_rate_last_week) || 0,
        interaction_rate_last_week: parseFloat(apiData[week].interaction_rate_last_week) || 0,
        engagement_per_post_last_week: parseFloat(apiData[week].engagement_per_post_last_week) || 0,
        content_engagement_quality_last_week: parseFloat(apiData[week].content_engagement_quality_last_week) || 0,
        level_engagement_rate_last_week: apiData[week].level_engagement_rate_last_week,
        level_engagement_per_post_last_week: apiData[week].level_engagement_per_post_last_week,
        level_content_engagement_quality_last_week: apiData[week].level_content_engagement_quality_last_week,
        level_interaction_rate_last_week: apiData[week].level_interaction_rate_last_week,
        level_score_last_week: apiData[week].level_score_last_week,
        global_average_score: parseFloat(apiData[week].global_average_score) || 0,
        global_average_number_post: parseInt(apiData[week].global_average_number_post, 10) || 0,
        number_posts_no_likable: parseInt(apiData[week].number_posts_no_likable, 10) || 0,
        global_average_number_likes: parseInt(apiData[week].global_average_number_likes, 10) || 0,
        global_average_number_comments: parseInt(apiData[week].global_average_number_comments, 10) || 0,
        global_average_engagement_rate_score: parseFloat(apiData[week].global_average_engagement_rate_score) || 0,
        global_average_engagement_per_post_score: parseFloat(apiData[week].global_average_engagement_per_post_score) || 0,
        global_average_content_engagement_qualit_score: parseFloat(apiData[week].global_average_content_engagement_qualit_score) || 0,
        global_average_interaction_rate_score: parseFloat(apiData[week].global_average_interaction_rate_score) || 0,
        
        // New fields
        previous_followers_count: parseInt(apiData[week].previous_followers_count, 10) || 0,
        followers_growth_last_week: parseFloat(apiData[week].followers_growth_last_week) || 0,
        global_average_followers_growth: parseFloat(apiData[week].global_average_followers_growth) || 0,
        level_followers_growth_last_week: apiData[week].level_followers_growth_last_week,
      }));

      setData(validData);

      const highestWeek = sortedWeeks[sortedWeeks.length - 1];
      const followersTotal = parseInt(apiData[highestWeek].followers_number_total, 10);

      onFollowersUpdate(followersTotal);

      if (chartContainerRef.current) {
        chartContainerRef.current.scrollLeft = chartContainerRef.current.scrollWidth;
      }

      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };



  const barWidth = 50;
  let underWithMobile = 20;

  if (windowWidth < 768) {
    underWithMobile = 70;
  }


  const chartHeight = windowWidth < 768 ? 200 : 275;
  const yMax = Math.max(...data.map(d => d.y));

  if (loading) {
    return <div>Loading...</div>;
  }

  let basedLength = 7;
  if (data.length > 7) {
    basedLength = data.length;
  }

  return (
    <>
      <ScrollToRightDiv>
        <div style={{ minWidth: `${basedLength * (barWidth + 30)}px` }}>
          <VictoryChart
            width={basedLength * barWidth}
            height={chartHeight / 1.1}
            padding={{ top: 60, bottom: 20, left: 20, right: 20 }}
            domainPadding={{ x: 60, y: 0 }}
            domain={{ y: [0, yMax] }}
          >
            <VictoryBar
              data={data}
              cornerRadius={{ top: 3 }}
              style={{
                data: {
                  fill: ({ index }) => (index === data.length - 1 ? '#570327' : '#93003F'),
                  strokeWidth: 2,
                },
              }}
              barWidth={barWidth - 30}
              alignment="middle"
              events={[
                {
                  target: 'data',
                  eventHandlers: {
                    onClick: (event, barData) => {
                      onBarClick(barData); // Trigger on bar click
                    },
                  },
                },
              ]}
              labels={({ datum }) => {
                const formatter = new Intl.NumberFormat('en-US', {
                  useGrouping: true,
                });
                // Format the number and replace commas with spaces
                return formatter.format(datum.y).replace(/,/g, ' ');
              }}
              labelComponent={
                <VictoryLabel
                  dy={-10}
                  style={[
                    {
                      fill: ({ datum }) => {
                        const score = datum.y;
                        const globalAverage = parseFloat(datum.global_average_score);
                        console.log(score);
                        console.log(globalAverage);
                        if (score < 0.3 * globalAverage) {
                          return 'rgb(242 36 36)';
                        } else if (score >= 0.3 * globalAverage && score < 0.8 * globalAverage) {
                          return 'rgb(255 112 8)';
                        } else if (score >= 0.8 * globalAverage && score < 1.2 * globalAverage) {
                          return 'rgb(255 234 8)';
                        } else if (score >= 1.2 * globalAverage && score < 1.5 * globalAverage) {
                          return 'rgb(8 238 255)';
                        } else if (score >= 1.5 * globalAverage) {
                          return 'rgb(54 242 4)';
                        } else {
                          return '#FFFFFF';
                        }
                      },
                      fontSize: 10,
                      fontWeight: 'bold',
                    },
                  ]}
                />
              }
            />

            <VictoryAxis
              style={{
                axis: { stroke: 'none' },
                ticks: { stroke: 'none' },
                tickLabels: {
                  fill: '#FFFFFF',
                  fontSize: 7,
                  padding: 5,
                },
                grid: { stroke: 'none' },
              }}
            />
            <VictoryAxis
              dependentAxis
              style={{
                axis: { stroke: 'none' },
                ticks: { stroke: 'none' },
                tickLabels: { fill: 'none' },
                grid: { stroke: 'none' },
              }}
            />
          </VictoryChart>
        </div>
      </ScrollToRightDiv>
    </>
  );
};

export default TalentPerformanceChart;
